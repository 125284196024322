import React, { useEffect } from "react";
import axios from "axios";
import { baseUrl, paykey } from "../env";
import { useState } from "react";
import ModalPopup from "./AddressModal";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { addPaymentValidate, getCart } from "../Api/AllApi";
import { errorMessage, successMessage } from "../common/errorModel";
import "./getAddress.css";
import { getGlobalData } from "../Api/saveData";

function GetAddress() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [getUserDetails, setUserDetails] = useState({});
  const [selectedAddress, setSelectedAddress] = useState({});
  const [paymentMode, setPaymentMode] = useState('offline');
  const [walletAmount, setWalletAmount] = useState();
  const [refresh, setRefresh] = useState(false);
  const [totalObj, setTotalObj] = useState({
    total: 0,
    ftotal: 0,
    deliveryCharges: 0
  });


  let vendorsId = localStorage.getItem("vendorId");

  useEffect(() => {
    axios
      .get(baseUrl + `customer/get`)
      .then((res) => {
        setWalletAmount(res.data?.data?.amount || 0)
        res.data.data.addressList = res.data.data.address.map((address) => ({
          address:
            address.houseNo +
            " " +
            address.street +
            " " +
            address.city +
            " " +
            address.state +
            ", " +
            address.country +
            ", " +
            address.pincode,
          fullname: address.fullname,
          mobile: address.mobile,
        }));
        setUserDetails(res.data.data);
      })
      .catch((err) => {
        console.log("Errors", err);
      });
  }, [refresh]);

  const AddressShow = () => {
    setShow(true);
  };

  const getCartDetails = () => {
    let token = localStorage.getItem("token");
    let vendorId = localStorage.getItem('vendorId');
    if (token && vendorId) {
      getCart(vendorId).then((resp) => {
        totalObj.total = 0;
        totalObj.ftotal = 0;
        totalObj.deliveryCharges = getGlobalData('vendorInfo')?.deliveryCharges || 0;
        let cartList = resp.data.data;
        cartList.forEach((item) => {
          totalObj.totalQty += item.qty
          item.fprice = item.productId.price;
          item.productId.offerPrice?.forEach((price) => {
            if (price.qty <= item.qty) {
              item.fprice =
                item.productId.price - (item.productId.price * price.price) / 100;
            }
          });
          totalObj.total += item.qty * item.productId.price;
          totalObj.ftotal += item.qty * item.fprice;

        });
        if (totalObj.total >= getGlobalData('vendorInfo')?.minOrder) {
          totalObj.deliveryCharges = 0
        }
        setTotalObj({ ...totalObj })
      }).catch(err => {
        errorMessage('Error while fetching order details')
      })
    }
  };

  const handleAddressSelection = (address, index) => {
    setSelectedAddress({ ...address, index });
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...getUserDetails, [name]: value });
  };

  // let cartValue = localStorage.getItem('cart')
  // let cartData = JSON.parse(cartValue)

  const submitVal = (e) => {
    e.preventDefault();
    if (!selectedAddress?.fullname) {
      errorMessage("Please select address and Retry")
      return
    }
    let data = {
      name: selectedAddress?.fullname,
      mobile: selectedAddress?.mobile,
      address: selectedAddress?.address,
      vendorId: vendorsId,
      paymentMode
    };

    let config = {
      method: "POST",
      url: baseUrl + "order/create",
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        let orderData = response.data?.data
        if (orderData.paymentMode === 'offline'|| orderData.paymentMode === 'wallet') {
          successMessage("Your Order Placed Successfully!")
          navigate("/orderlist");
        } else {
          const options = {
            key: paykey, // Replace with your Razorpay key_id
            amount: orderData?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: 'INR',
            name: 'SHREWDMIND PVT LTD',
            description: 'Test Transaction',
            order_id: orderData.payOrderId, // This is the order_id created in the backend
            handler: (e) => {
              addPaymentValidate({ payId: e.razorpay_payment_id, signature: e.razorpay_signature, orderId: orderData?.payOrderId, amount: orderData?.amount })
                .then(resp => {
                  successMessage('Order Successfully Placed')
                  navigate("/orderlist");
                }).catch(err => {
                  errorMessage('Error while placing order')
                })
            },
            prefill: {
              name: orderData.name,
              email: orderData.email,
              contact: orderData.mobile
            },
            theme: {
              color: '#F37254'
            },
          };

          const rzp = new window.Razorpay(options);
          rzp.open();
        }

      })
      .catch((error) => {
        errorMessage('Error while placing order')
      });
  };
  useEffect(() => {
    // calculation();
    getCartDetails()
  }, []);

  return (
    <>
      <section className="checkout spad">
        <div className="container">
          <div className="checkout__form">
            <h5>Order Details  <span onClick={AddressShow}>
              + Add Address
            </span></h5>

            <Form onSubmit={submitVal}>
              <div className="row">
                <div className="col-lg-8 col-md-6">
                  {getUserDetails?.addressList?.map((addressObj, i) => (
                    <>
                      <label
                        key={i}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleAddressSelection(addressObj, i)}
                        className={`card ${selectedAddress.index === i ? 'selected-address' : ''}`}
                        for={"addr" + i}
                      >

                        <div className="card-body"><input
                          type="radio"
                          name="address"
                          id={"addr" + i}
                          style={{ cursor: "pointer", float: "right" }}
                        />
                          <h5 className="card-title" style={{ fontSize: '16px' }}>
                            <b style={{ paddingRight: "5px" }}>Name:-</b>
                            {addressObj.fullname}
                          </h5>
                          <h5 className="card-title" style={{ fontSize: '16px' }}>
                            <b style={{ paddingRight: "5px" }}>Mobile:-</b>
                            {addressObj.mobile}
                          </h5>
                          <p className="card-text" style={{ fontSize: '16px' }}>{addressObj.address}</p>

                        </div>
                      </label>
                    </>
                  ))}

                  <ModalPopup
                    show={show}
                    setShow={setShow}
                    setRefresh={setRefresh}
                    refresh={refresh}
                  />
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="checkout__order">
                    <h4>Your Order</h4>
                    <div className="checkout__order__products">
                      Products <span>Total</span>
                    </div>
                    {/* {value?.length > 0 &&
                      value?.map((data, i) => (
                        <ul key={i}>
                          <li>
                            {data?.name}{" "}
                            <span> ₹{data?.qty * data?.fprice}</span>
                          </li>
                        </ul>
                      ))} */}
                    <div className="checkout__order__products">
                      Subtotal <span>₹{totalObj.total} </span>
                    </div>
                    <div className="checkout__order__products">
                      Delivery Charges <span>₹{totalObj.deliveryCharges} </span>
                    </div>
                    <div className="checkout__order__total">
                      Discount <span>₹{totalObj.total - totalObj.ftotal} </span>
                    </div>
                    <div className="checkout__order__products">
                      Total <span style={{ color: "green" }}>₹{totalObj.ftotal + totalObj.deliveryCharges}</span>
                    </div>
                    <strong>Payment Mode</strong>
                    <div className="row mt-2 payment_type">
                      <label className="col">
                        <input
                          type="radio"
                          name="paymentMode"
                          defaultChecked={paymentMode === 'offline'}
                          onClick={() => { setPaymentMode('offline') }}
                        /> COD
                      </label>
                      <label className="col">
                        <input
                          type="radio"
                          name="paymentMode"
                          onClick={() => { setPaymentMode('online') }}
                          defaultChecked={paymentMode === 'online'}
                        /> Online</label>
                      <label className="col">
                        <input
                          type="radio"
                          name="paymentMode"
                          disabled={walletAmount < (totalObj.ftotal + totalObj.deliveryCharges)}
                          defaultChecked={paymentMode === 'wallet'}
                          onClick={() => { setPaymentMode('wallet') }}
                        /> Wallet (₹{walletAmount})
                      </label>

                    </div>
                    <button
                      type="submit"
                      className="site-btn"
                    >
                      PLACE ORDER
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
}

export default GetAddress;
