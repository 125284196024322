import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { baseUrl } from "../env";
import OtpVarificationModal from "./OtpVarificationModal";

function LoginModal(props) {
  const { show, setShow } = props;
  const [visible, setVisible] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const handleShow = () => {
    setVisible(true);
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData({ ...formData, [name]: value });
  };
  const handleClose = () => setShow(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      username: formData.email,
    };
    localStorage.setItem("data", formData.email);
    let config = {
      method: "POST",
      url: baseUrl + "auth/login",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        localStorage.setItem("otptoken", response.data.data.token);
        handleShow();
        handleClose();
      })
      .catch((error) => {
        console.log("Errors:-", error);
      });
  };
  return (
    <div>
      <Modal show={show} centered size="lg">
        <Form onSubmit={handleSubmit}>
          <Modal.Body style={{backgroundColor:"#def7de"}}>
            <div className="modal_flex_wrap">
              <div className="modal_box_img">
                <img
                  src="../Assets/images/LoginModel.jpg"
                  className="img-fluid"
                />
              </div>
              <div className="modal_box">
                <h5>Login</h5>
                <Form.Group controlId="formName">
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <div className="modal_btn_wrap">
                  <Button variant="light" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="success" type="submit">
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
      <OtpVarificationModal visible={visible} setVisible={setVisible} />
    </div>
  );
}

export default LoginModal;
