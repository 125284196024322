import React, { useState } from "react";
import { useEffect } from "react";
import { getApi, getOfferByVendorId } from "../Api/AllApi";
import "./home.css";

import { baseUrl } from "../env";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css";
import { getGlobalData } from "../Api/saveData";

let cartlist = [];
let cartids = [];

function Home() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [peoductList, setProductList] = useState(null);
  const [activeCat, setActiveCat] = useState(0);
  const [catDropdown, setCatDropdown] = useState(false);
  const [items, setItems] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  let { vendorid } = useParams();
  let vendorId = vendorid || localStorage.getItem("vendorId");
  const [key, setKey] = useState("home");
  const [vendorInfo, setVendorInfo] = useState({});
  const [offerList, setOfferList] = useState([]);

  useEffect(() => {
    if (vendorId) {
      localStorage.setItem("vendorId", vendorId);
      document.addEventListener("vendorInfo", (e) => {
        setVendorInfo(getGlobalData("vendorInfo") || {});
      });
    } else {
      navigate("/shopList");
    }
    getApi(vendorId)
      .then((res) => {
        if (res.data.data) {
          setUserData([...res.data.data]);
          setKey(res.data.data[0].name);
          getProduct(res.data.data[0].productList, 0);
        }
      }).catch((err) => console.log(err, "error"));
      getOfferByVendorId(vendorId).then(resp=>{
        setOfferList(resp.data.data)
        console.log('offers----------------',resp.data.data)
      }).catch(err=>console.log('offer--------err',err))
  }, [vendorId]);



  // useEffect(()=>{
  //     console.log('vendor info----------------------',getGlobalData('vendorInfo'))
  //     setVendorInfo({...})
  // },[getGlobalData('vendorInfo')])

  const addtoCart = (obj, i) => {
    if (obj.qty) obj.qty += 1;
    else obj.qty = 1;
    cartids = cartlist.map((i) => i._id);
    const index = cartids.indexOf(obj._id);
    if (index >= 0) {
      cartlist[index] = obj;
    } else {
      cartlist.push(obj);
    }
    // localStorage.setItem('cart-'+vendorId, JSON.stringify(cartlist))
    setItems([...items]);
    createUpdateEvent();
  };
  const createUpdateEvent = () => {
    const event = new Event("localstorage");
    event.key = "test";
    event.value = "hello";
    document.dispatchEvent(event);
  };
  const deleteCart = (obj, i) => {
    if (obj.qty) {
      if (obj.qty === 1) {
        obj.qty = null;
        cartlist.splice(cartids.indexOf(obj.id), 1);
      } else {
        obj.qty -= 1;
        cartlist[cartids.indexOf(obj.id)] = obj;
      }
      // localStorage.setItem('cart-'+vendorId, JSON.stringify(cartlist))
      setItems([...items]);
      createUpdateEvent();
    }
  };

  const getProduct = (products, i) => {
    setActiveCat(i);
    setCatDropdown(false);
    if (products && products.length) {
      const str = localStorage.getItem("cart-" + vendorId);
      cartlist = str ? JSON.parse(str) : [];
      cartids = cartlist.map((i) => i._id);
      if (cartids.length) {
        products.forEach((item) => {
          const ind = cartids.indexOf(item._id);
          if (ind >= 0) {
            item.qty = cartlist[ind].qty;
          }
        });
      }
      setProductList([...products]);
    } else {
      setProductList(null);
    }
  };

  const getDetails = (e, item) => {
    e.preventDefault();
    navigate("/productdetails/" + item._id);
  };
  const videodata = [
    {
      id: 0,
      name: "video1",
      url: "https://www.youtube.com/watch?v=UQ7YYrEbrps",
    },
    {
      id: 1,
      name: "video2",
      url: "https://www.youtube.com/watch?v=UQ7YYrEbrps",
    },
  ];

  return (
    <div>
      <section className="home-page">
        <div className="container mt-2">
          <div className="row">
            <div className="col-lg-12 col-md-7 cont">
              {/* header start here  */}

              <div className="banner_image">
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  autoplay={{
                    delay: 6000,
                  }}
                  loop={true}
                  modules={[Autoplay, Navigation]}
                >
                  {vendorInfo?.banner?.length
                    ? vendorInfo?.banner?.map((item) => 
                        <SwiperSlide>
                          <div className="banner-slide">
                            <img
                              src={baseUrl + "/file/load/" + item}
                              alt=""
                              className="banner-image"
                            />
                          </div>
                        </SwiperSlide>
                      )
                    : ""}
                  {!vendorInfo?.banner?.length ? (
                    <SwiperSlide>
                        <div className="banner-slide">
                            <img
                              src={baseUrl + "/file/load/" + vendorInfo?.logo}
                              alt=""
                              className="banner-image"
                            />
                          </div>
                    </SwiperSlide>
                  ) : (
                    ""
                  )}
                </Swiper>
              </div>
              {/* header end here  */}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              {/* category slider start here  */}
              <div className="slider_wrapper my-5">
                <div className="offer-zone">
                  <h4>Daily Deals</h4>
                </div>
                <Swiper
                  spaceBetween={50}
                  breakpoints={{
                    320: {
                      // width: 576,
                      slidesPerView: 2,
                    },
                    576: {
                      // width: 576,
                      slidesPerView: 2,
                    },
                    768: {
                      // width: 768,
                      slidesPerView: 4,
                    },
                  }}
                  navigation
                  // autoplay={{
                  //   delay: 25000000,
                  // }}
                  loop={true}
                  modules={[Autoplay, Navigation]}
                >
                  {offerList.map((item, i) => (
                    <SwiperSlide>
                      <div className="category_box">
                        <Link to={"/productdetails/"+item.product?._id}>
                          <img
                            style={{ width: "100%", maxHeight: "30vh" }}
                            src={baseUrl + "/file/load/" + item.product?.images[0]}
                            // src="../Assets/images/BannerVegi1.jpg"
                            alt="cat"
                          />
                          <h5>{item.product.name}<p style={{fontSize:'10px'}}>order {item.minAmount} get {item.offerAmount}</p></h5>
                          
                        </Link>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              {/* category slider end here  */}
              <div className="offer-zone">
                <h4>Categories</h4>
              </div>
              <div className="row category-section">
                {userData.map((item, i) => (
                  <div className="col-6 col-md-3 mt-2 mb-2">
                    <div className="category_box">
                      <Link to={"/category?cat=" + i}>
                        <div
                          className="product__item__pic set-bg"
                          style={{
                            backgroundImage:
                              "url(" +
                              baseUrl +
                              "/file/load/" +
                              item?.image +
                              ")",
                          }}
                        >
                          <h5>{item.name}</h5>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>

              {/* Featured Product start here  */}

              {/* Featured Product end here  */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Home;
