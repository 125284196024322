import React, { useState } from "react";
import { useEffect } from "react";
import { getApi } from "../Api/AllApi";
// import imageData from "../../public/Assets/images/iphone-14.jpg"
import "./home.css";
import { baseUrl } from "../env";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
let cartlist = [];
let cartids = [];

let CategoryDetail = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [peoductList, setProductList] = useState(null);
  const [activeCat, setActiveCat] = useState(0);
  const [catDropdown, setCatDropdown] = useState(false);
  const [items, setItems] = useState([]);
  let { vendorid } = useParams();
  const [searchParams] = useSearchParams();
  let vendorId = vendorid || localStorage.getItem("vendorId");

  useEffect(() => {
    if (vendorId) {
      localStorage.setItem("vendorId", vendorId);
    } else {
      navigate("/shopList");
    }
    getApi(vendorId)
      .then((res) => {
        if (res.data.data) {
          setUserData([...res.data.data]);
          let index = +searchParams.get("cat") || 0;
          getProduct(res.data.data[index].productList, index);
        }
      })

      .catch((err) => console.log(err, "error"));
  }, [vendorId]);

  const addtoCart = (obj, i) => {
    if (obj.qty) obj.qty += 1;
    else obj.qty = 1;
    cartids = cartlist.map((i) => i._id);
    const index = cartids.indexOf(obj._id);
    if (index >= 0) {
      cartlist[index] = obj;
    } else {
      cartlist.push(obj);
    }
    // localStorage.setItem('cart-'+vendorId, JSON.stringify(cartlist))
    setItems([...items]);
    createUpdateEvent();
  };
  const createUpdateEvent = () => {
    const event = new Event("localstorage");
    event.key = "test";
    event.value = "hello";
    document.dispatchEvent(event);
  };
  const deleteCart = (obj, i) => {
    if (obj.qty) {
      if (obj.qty === 1) {
        obj.qty = null;
        cartlist.splice(cartids.indexOf(obj.id), 1);
      } else {
        obj.qty -= 1;
        cartlist[cartids.indexOf(obj.id)] = obj;
      }
      // localStorage.setItem('cart-'+vendorId, JSON.stringify(cartlist))
      setItems([...items]);
      createUpdateEvent();
    }
  };

  const getProduct = (products, i) => {
    setActiveCat(i);
    setCatDropdown(false);
    if (products && products.length) {
      const str = localStorage.getItem("cart-" + vendorId);
      cartlist = str ? JSON.parse(str) : [];
      cartids = cartlist.map((i) => i._id);
      if (cartids.length) {
        products.forEach((item) => {
          const ind = cartids.indexOf(item._id);
          if (ind >= 0) {
            item.qty = cartlist[ind].qty;
          }
        });
      }
      setProductList([...products]);
    } else {
      setProductList(null);
    }
  };

  const getDetails = (e, item) => {
    e.preventDefault();
    navigate("/productdetails/" + item._id);
  };

  return (
    <div>
      <section className="hero hero-normal mcat">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="hero__categories">
                <div
                  className="hero__categories__all"
                  onClick={() => setCatDropdown(!catDropdown)}
                >
                  <i className="fa fa-bars"></i>
                  <span>All departments</span>
                </div>
                <ul style={{ display: catDropdown ? "block" : "none" }}>
                  {userData.map((item, i) => (
                    <li
                      key={"mcat" + i}
                      className={`${
                        activeCat === i ? "catactive" : "catbutton"
                      }`}
                      onClick={() => getProduct(item.productList, i)}
                    >
                      <a>{item.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-page">
        <div className="container mt-2">
          <div className="row">
            <div className="col-lg-3 col-md-5 dcat">
              <div className="sidebar">
                <div className="sidebar__item">
                  <h4>Category</h4>
                  <ul>
                    {userData.map((item, i) => (
                      <div
                        className={`${
                          activeCat === i ? "catactive" : "catbutton"
                        }`}
                        key={"dcat" + i}
                        onClick={() => getProduct(item.productList, i)}
                      >
                        <li>
                          <a>{item.name}</a>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-9 col-md-7 cont" style={{height:'80vh',overflow:"auto",scrollbarWidth:'none'}}> */}
            <div className="col-lg-9 col-md-7 cont">
              <div className="">
                <div className="section-title product__discount__title">
                  <h2>{userData[activeCat]?.name}</h2>
                </div>
              </div>
              <div className="row category-section">
                {peoductList &&
                  peoductList?.map((item, i) => (
                    <div
                      key={"product" + i}
                      className="col-lg-3 col-md-3 col-sm-4 col-6 p-2"
                    >
                      <div
                        className="product__item"
                        onClick={(e) => getDetails(e, item)}
                      >
                        <div
                          className="product__item__pic set-bg"
                          style={{
                            backgroundImage:
                              "url(" +
                              baseUrl +
                              "/file/load/" +
                              item?.images[0] +
                              ")",
                          }}
                        ></div>
                        <div className="product__item__text">
                          <div className="item_name_container">
                            <p className="item_name">{item?.name}</p>
                          </div>
                          <div className="item_price_container">
                            <p className="item_price">
                              <i className="fa fa-inr" aria-hidden="true"></i>&nbsp;
                              {item?.price}
                            </p>
                          </div>
                          <div className="item_offers_container">
                            <p className="item_offers">
                              BUY {item.offerPrice[0]?.qty} GET{" "}
                              {item.offerPrice[0]?.price}% OFF
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {!peoductList && (
                  <span style={{ color: "red" }}>No data Found</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CategoryDetail;
