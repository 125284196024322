import React from 'react'
import { BrowserRouter, HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import Footer from '../component/Footer';
import Header from '../component/Header';
import Home from '../component/Home';
import Shop from '../component/Shop';
import Cart from '../pages/Cart';
import Address from '../pages/Address';
import GetAddress from '../pages/GetAddress';
import OrderList from '../pages/OrderList';
import OrderDetails from '../pages/OrderDetails';
import ProductDetails from '../pages/ProductDetails';
import ContactUs from '../pages/ContactUs';
import NotFound from '../component/NotFound';
import AboutUs from '../pages/AboutUs';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import OurService from '../pages/OurService';
import TermsAndConditions from '../pages/TermsAndConditions';
import CategoryDetail from '../component/CategoryDetail'
import Wallet from '../pages/Wallet';
import ProtectedRoute from "./ProtectedRoute"


const LayoutWithHeader = ({ children }) => (
    <div>
      <Header />
      <main>{children}</main>
    </div>
  );

function AllRoute() {
    return (
        <HashRouter>
            <Routes>
                 {/* <Route path="/dashboard" element={<ProtectedRoute component={DashboardPage} />} /> */}
                <Route exact path='/shopList' element={<Shop />} />
                <Route exact path='/:vendorid' element={<LayoutWithHeader><Home /></LayoutWithHeader>} />
                <Route exact path='/' element={<LayoutWithHeader><Home /></LayoutWithHeader>} />
                <Route exact path='/productdetails/:id' element={<LayoutWithHeader><ProductDetails /></LayoutWithHeader>} />
                <Route exact path='/urlnotfound' element={<LayoutWithHeader><NotFound /></LayoutWithHeader>} />
                <Route exact path='/aboutus' element={<LayoutWithHeader><AboutUs /></LayoutWithHeader>} />
                <Route exact path='/privacy' element={<LayoutWithHeader><PrivacyPolicy /></LayoutWithHeader>} />
                <Route exact path='/service' element={<LayoutWithHeader><OurService /></LayoutWithHeader>} />
                <Route exact path='/terms' element={<LayoutWithHeader><TermsAndConditions /></LayoutWithHeader>} />
                <Route exact path='/category' element={<LayoutWithHeader><CategoryDetail /></LayoutWithHeader>} />

                {/* protected routes starts here */}

                <Route path="*" element={<ProtectedRoute element={<Shop />} />} />
                <Route exact path='/getaddress' element={<LayoutWithHeader><ProtectedRoute element={<GetAddress />} /></LayoutWithHeader>} />
                <Route exact path="/cart" element={<LayoutWithHeader><ProtectedRoute element={<Cart />} /></LayoutWithHeader>} />
                <Route exact path='/address' element={<LayoutWithHeader><ProtectedRoute element={<Address />} /></LayoutWithHeader>} />
                <Route exact path='/contactus' element={<LayoutWithHeader><ProtectedRoute element={<ContactUs />} /></LayoutWithHeader>} />
                <Route exact path='/orderlist' element={<LayoutWithHeader> <ProtectedRoute element={<OrderList />} /></LayoutWithHeader>} />
                <Route exact path='/orderdetails/:orderId' element={<LayoutWithHeader><ProtectedRoute element={<OrderDetails />} /></LayoutWithHeader>} />
                <Route exact path='/wallet' element={<LayoutWithHeader><ProtectedRoute element={<Wallet />} /></LayoutWithHeader>} />
           {/* protected routes ends here */}
            </Routes>
            <Footer />
        </HashRouter>

    )
}

export default AllRoute;
