import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <>
      <footer className="footer mt-auto ">
        {/* <div className="container"> */}
          <div className=" footer_bg px-2">
            <div className="row text-center">
              <div className="col-lg-6 col-md-6 col-sm-6  text-white">
                <div className="footer__widget mt-4">
                  {/* <h6>Useful Links</h6> */}
                  <ul>
                    <li>
                      <Link to="/aboutus">About Us</Link>
                    </li>

                    <li>
                      <Link to="/terms">Terms and Condition</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="/service">Our Services</Link>
                    </li>
                    <li>
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/contactus">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 text-white">
                <div className="footer__about">
                  <ul>
                    <li className = "text-white">
                      Address: D-298 Street No 11 Harsh Vihar, 110093 New Delhi
                    </li>
                    <li>Email: support@pickupskart.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="col-lg-12">
              <div className="footer__copyright">
                <div className="text-center my-sm-2 my-md-3 my-lg-0">
                  <p className="footer_copyright">
                    Copyright &copy;
                    <script>document.write(new Date().getFullYear());</script>{" "}
                    All rights reserved | Made{" "}
                    <i className="fa fa-heart" aria-hidden="true"></i> by{" "}
                    <a href="#" target="_blank">
                      Pickups
                    </a>
                  </p>
                </div>
                <div className="footer__copyright__payment">
                  <img src="img/payment-item.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
      </footer>
    </>
  );
}
export default Footer;
