import React, { useEffect, useState } from "react";
import "./Wallet.css";
import { addMoneyWallet, addPaymentValidate, getWaletHistory } from "../Api/AllApi";
import { paykey } from "../env";
import { errorMessage, successMessage } from "../common/errorModel";
import { dateFormatDDMMYYYY } from "../common/commonService";

const Wallet = () => {

  const [isAddMoney, setAddMoney] = useState(false)
  const [amount, setAmount] = useState(1)
  const [history, setHistory] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)


  useEffect(() => {
    getHistory()
  }, [])

  const getHistory = () => {
    getWaletHistory().then(resp => {
      if (resp.data?.data) {
        setTotalAmount(resp.data?.data?.amount || 0)
        if (resp.data?.data?.history && resp.data?.data?.history.length) {
          setHistory([...resp.data?.data?.history])
        }
      }
    })
  }

  const addMoney = () => {
    if (!amount) return
    addMoneyWallet({ amount }).then((response) => {
      let orderData = response.data?.data
      const options = {
        key: paykey,
        amount: orderData?.amount,
        currency: 'INR',
        name: 'SHREWDMIND PVT LTD',
        description: 'Test Transaction',
        order_id: orderData.payOrderId,
        handler: (e) => {
          addPaymentValidate({ payId: e.razorpay_payment_id, signature: e.razorpay_signature, orderId: orderData?.payOrderId, amount: orderData?.amount })
            .then(resp => {
              successMessage('Successfully added')
              setAddMoney(false)
              getHistory()
            }).catch(err => {
              errorMessage('Error while adding money')
            })
        },
        // callback_url: 'http://localhost:3000/#/wallet',
        prefill: {
          name: orderData.name,
          email: orderData.email,
          contact: orderData.mobile
        },
        theme: {
          color: '#F37254'
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();

    })
      .catch((error) => {
        errorMessage('Error while placing order')
      });
  }

  return (
    <div className="container p-4 wallet-container">
      <div className="text-center p-4 card-container">
        <h2 className="fw-semibold">₹{totalAmount}</h2>
        {!isAddMoney && <button className="balance-button" variant="outline-primary" onClick={() => { setAddMoney(true); setAmount(1) }} >Add Balance</button>}
        {isAddMoney && <>
          <input className="balance-button" value={amount} onChange={(e) => setAmount(e.target.value || 0)} placeholder="Enter amount" type="number" />
          <div className="row m-0 p-0">
            <button className="col-5 balance-button mr-2" variant="outline-primary" onClick={addMoney} >Add</button>
            <button className="col-6 balance-button" variant="outline-primary" onClick={() => setAddMoney(false)} >Cancel</button>
          </div>
        </>}
      </div>
      {/* <div className="row my-4 text-center">
        <div className="col-6 col-md-3 mb-2"><Button variant="outline-secondary" className="w-100"><FaArrowDown /> Withdraw</Button></div>
        <div className="col-6 col-md-3 mb-2"><Button variant="outline-secondary" className="w-100"><FaArrowUp /> Send</Button></div>
        <div className="col-6 col-md-3 mb-2"><Button variant="outline-secondary" className="w-100"><FaCreditCard /> Cards</Button></div>
        <div className="col-6 col-md-3 mb-2"><Button variant="outline-secondary" className="w-100"><FaExchangeAlt /> Exchange</Button></div>
      </div>

      <div className="row">
        <div className="col-6 col-md-3 mb-2"><div className="p-3 bg-light text-center card"><p>Income</p><h5>$485.50</h5></div></div>
        <div className="col-6 col-md-3 mb-2"><div className="p-3 bg-light text-center card"><p>Expenses</p><h5>$95.50</h5></div></div>
        <div className="col-6 col-md-3 mb-2"><div className="p-3 bg-light text-center card"><p>Total Bills</p><h5>$75.00</h5></div></div>
        <div className="col-6 col-md-3 mb-2"><div className="p-3 bg-light text-center card"><p>Savings</p><h5>$285.00</h5></div></div>
      </div> */}

      <div className="d-flex justify-content-between align-items-center mt-4">
        <h3>Transactions</h3>
        {/* <a href="#" className="text-primary">View All</a> */}
      </div>
      <div>
        {history.map((tx) => (
          <div key={tx.id} className="p-3 my-2 shadow-sm card">
            <div className="row">
              <div className="col-8">
                <p >{tx.paymentType}</p>
                <p style={{ fontSize: '12px' }}>{dateFormatDDMMYYYY(tx.createdAt)}</p>
              </div>

              <div className="col-4">
                <p className={`mb-0 fw-bold ${tx.paymentType == 'debit' < 0 ? "text-danger" : "text-success"}`}>
                  {tx.paymentType == 'debit' ? `-₹${Math.abs(tx.amount)}` : `+₹${tx.amount}`}
                </p>
                <p style={{ fontSize: '12px' }}>{tx.status}</p>
              </div>
              <p className="col-12" style={{ fontSize: '12px' }}>{tx.paymentType == 'debit'?'Sent to ':'Received from '}{tx.remark}</p>
            </div>
            
          </div>

        ))}
      </div>
    </div>
  );
};

export default Wallet;
