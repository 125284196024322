import React from "react";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div>
      <div className="subpage-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="subpage-header-box">
                <h1 className="text-anime-style-3">About Us</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="page-about-content">
                <div className="section-title">
                  <h3 className="wow fadeInUp">about us</h3>
                  <h2 className="text-anime-style-3">
                    Instant shopping that feels like magic
                  </h2>
                </div>

                <div className="page-about-body">
                  <p className=" wow fadeInUp">
                    "We're revolutionizing e-commerce by making the things that
                    matter most to you—premium quality products—available at a
                    lower cost." Our goal is to free our customers from the
                    little everyday tasks, allowing them to focus on what truly
                    matters. We handle the chores.By leveraging cutting-edge
                    technology, data science, and deep customer insights.But our
                    ambition? To grow 100x in the next five years. To become one
                    of the leading e-retail companies of our generation, we need
                    exceptional builders—people who can think quickly, take
                    ownership, and make things happen. If you're ambitious,
                    smart, and ready to leave your ego at the door, we’d love to
                    hear from you.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-page-img">
                <div className="page-about-image">
                  <div className="page-about-image-1">
                    <img src="../Assets/images/carousel-1.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="we-improving">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
            <div className="about-page-img">
                <div className="page-about-image">
                  <div className="page-about-image-1">
                    <img src="../Assets/images/carousel-1.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="improving-content">
                <div className="section-title">
                  <h3 className="wow fadeInUp">who we are</h3>
                  <h2 className="text-anime-style-3">
                    "Improving your quality of life by providing Commerce so
                    fast, it seems like magic.."
                  </h2>
                </div>
                <div className="improving-body">
                  <p className="wow fadeInUp">
                    we offer a wide range of high-quality products, from fresh
                    groceries to packaged foods, household supplies, personal
                    care items, and more. With a focus on customer satisfaction
                    and convenience, we are committed to delivering a seamless
                    shopping experience to every household.
                  </p>
                </div>

                {/* <div
                  className="improving-btn wow fadeInUp"
                  data-wow-delay="0.25s"
                >
                  <a
                    href="https://www.youtube.com/watch?v=Y-x0efG1seA"
                    className="btn-video popup-youtube"
                  >
                    <i className="fas fa-play-circle"></i> Play Video
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="top-services">
        <div className="container">
          <div className="row section-row">
            <div className="col-lg-12 col-md-12">
              <div className="section-title">
                <h3 className="wow fadeInUp">Why Shop With Us?</h3>
                <h4>
                  Our Vision To revolutionize the way people shop for groceries
                  and daily essentials by providing easy access to quality
                  products at lowest prices
                </h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="services-item wow fadeInUp">
                <div className="services-img">
                  <img src="images/icon-service-1.svg" alt="" />
                  <img
                    className="services-icon-white"
                    src="images/icon-service-white-1.svg"
                    alt=""
                  />
                </div>
                <div className="services-content">
                  <h3>Customer-Centered Service</h3>
                  <p>
                    Your satisfaction is our top priority. We strive to provide
                    excellent customer service, with easy returns, fast
                    shipping, and responsive support.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12">
              <div className="services-item wow fadeInUp" data-wow-delay="0.25s">
                <div className="services-img">
                  <img src="images/icon-service-2.svg" alt="" />
                  <img
                    className="services-icon-white"
                    src="images/icon-service-white-2.svg"
                    alt=""
                  />
                </div>
                <div className="services-content">
                  <h3>Convenience</h3>
                  <p>
                    Shop from the comfort of your home and have your orders
                    delivered right to your door.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <div className="services-item" data-wow-delay="0.5s">
                <div className="services-img">
                  <img src="images/icon-service-3.svg" alt="" />
                  <img
                    className="services-icon-white"
                    src="images/icon-service-white-3.svg"
                    alt=""
                  />
                </div>
                <div className="services-content">
                  <h3>Affordable Prices</h3>
                  <p>
                    We believe in offering great value for your money, with
                    regular promotions and competitive pricing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        
        <div className="container py-5">
          
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h1 className="mb-0">Our Mission</h1>
              </div>
              <p className="mb-4">
                <ul>
                  <li>
                    <b>Quality</b>: We work closely with trusted suppliers to
                    bring you only the finest products, from fresh produce to
                    premium pantry staples.
                  </li>
                  <li>
                    <b>Affordability</b>: We believe that everyone should have
                    access to high-quality goods without breaking the bank. We
                    offer competitive pricing, discounts, and promotions to make
                    shopping affordable
                  </li>
                  <li>
                    <b>Convenience</b>: With just a few clicks, shop from the
                    comfort of your home and have your products delivered right
                    to your doorstep. We prioritize quick and reliable delivery
                    to ensure that you always have what you need when you need
                    it.
                  </li>
                </ul>
                <br></br>
                We're more than just an eCommerce platform – we’re your partner
                in convenience and quality. Thank you for choosing us as your
                preferred shopping destination. We look forward to serving you!
              </p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <p style={{ marginLeft: "5px" }}>support@pickupskart.com</p>
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i className="fa fa-address-book" aria-hidden="true"></i>
                    <p style={{ marginLeft: "5px" }}>
                      +91- 8076877163 ,+91- 8059942293
                    </p>
                  </h5>
                </div>
                <div className="col-sm-9 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i className="fa fa-address-book" aria-hidden="true"></i>
                    <p style={{ marginLeft: "5px" }}>
                      D-298 Street No 11 Harsh Vihar, 110093 New Delhi
                    </p>
                  </h5>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-25">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src="../Assets/images/carousel-1.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
