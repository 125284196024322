import React from "react";

function ContactUs() {
  return (
    <div>
      <div className="subpage-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="subpage-header-box">
                <h1 className="text-anime-style-3">Contact Us</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="page-about-content">
                <div className="section-title">
                  <h2 className="text-anime-style-3">We're here to help.</h2>
                </div>

                <div className="page-about-body">
                  <p className=" wow fadeInUp">
                    Have an issue with an order or feedback for us? Our support
                    team is here to help you from .
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-page-img">
                <div className="page-about-image">
                  <div className="page-about-image-1">
                    <img src="../Assets/images/carousel-1.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 mb-3 mt-5">
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                <h5 className="mb-3 row">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <p style={{ marginLeft: "10px" }}>support@pickupskart.com</p>
                </h5>
              </div>
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                <h5 className="mb-3 row">
                  <i className="fa fa-address-book" aria-hidden="true"></i>
                  <p style={{ marginLeft: "10px" }}>
                    +91- 8076877163 , +91- 8059942293
                  </p>
                </h5>
              </div>
              <div className="col-sm-9 wow zoomIn" data-wow-delay="0.2s">
                <h5 className="mb-3 row">
                  <i className="fa fa-address-book" aria-hidden="true"></i>
                  <p style={{ marginLeft: "10px" }}>
                    D-298 Street No 11 Harsh Vihar, 110093 New Delhi
                  </p>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div>
    //     <section className="contact spad">
    //         <div className="container">
    //             <div className="row">
    //                 <div className="col-lg-4 col-md-4 col-sm-6 text-center">
    //                     <div className="contact__widget">
    //                         <span className="icon_phone"></span>
    //                         <h4>Phone</h4>
    //                         <p>+91-8076877163</p>
    //                     </div>
    //                 </div>
    //                 <div className="col-lg-4 col-md-4 col-sm-6 text-center">
    //                     <div className="contact__widget">
    //                         <span className="icon_pin_alt"></span>
    //                         <h4>Address</h4>
    //                         <p>D-298/A Street No. 11 Harsh Vihar Delhi 110093</p>
    //                     </div>
    //                 </div>
    //                 <div className="col-lg-4 col-md-4 col-sm-6 text-center">
    //                     <div className="contact__widget">
    //                         <span className="icon_mail_alt"></span>
    //                         <h4>Email</h4>
    //                         <p>support@pickupskart.com</p>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </section>
    //     {/* <div className="map">
    //         <iframe
    //             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d49116.39176087041!2d-86.41867791216099!3d39.69977417971648!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886ca48c841038a1%3A0x70cfba96bf847f0!2sPlainfield%2C%20IN%2C%20USA!5e0!3m2!1sen!2sbd!4v1586106673811!5m2!1sen!2sbd"
    //             height="500" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

    //         <div className="map-inside">
    //             <i className="icon_pin"></i>
    //             <div className="inside-widget">
    //                 <h4>Noida</h4>
    //                 <ul>
    //                     <li>Phone: +12-345-6789</li>
    //                     <li>Add: Sarfabad Sector 73 Noida</li>
    //                 </ul>
    //             </div>
    //         </div>
    //     </div> */}
    // </div>
  );
}

export default ContactUs;
