import React, { useEffect, useState } from 'react';
import './BottomNav.css';  
import { BsDownload, BsShop } from 'react-icons/bs';

const BottomNav = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isApp,setIsApp]=useState()

  useEffect(()=>{
    if ("serviceWorker" in navigator && "PushManager" in window) {
      window.addEventListener("beforeinstallprompt", (e) => {
        setIsApp(e);
      });
    }else{
      setIsApp(null)
    }
  },[isApp])
  
  const checkFullscreen = () => {

    if (document.fullscreenElement) {
      setIsFullscreen(true);
    } else {
      setIsFullscreen(false);
    }
  };

 
  useEffect(() => {
    document.addEventListener('fullscreenchange', checkFullscreen);
    return () => {
      document.removeEventListener('fullscreenchange', checkFullscreen);
    };
  }, []);

  const installApp=()=>{
    isApp.prompt()
  }

  return (
    !isFullscreen && (  
      <div className="bottom-nav" style={{color:'white'}}>
        {isApp && <a onClick={installApp} className="nav-item">
          <BsDownload/>
          <span>Install App</span>
        </a>}
        {!isApp && <a href="/" className="nav-item">
          <i className="fa fa-home"></i>
          <span>Home</span>
        </a>}
        {/* <a href="#" className="nav-item">
          <i className="fa fa-heart"></i>
          <span>Favorites</span>
        </a> */}
        {/* <Link to="">
        
        </Link> */}
        <a href="/#/shoplist" className="nav-item" style={{color:'white'}}>
           <BsShop />
          <span className='mt-1'>Shops near me</span>
        </a>
      </div>
    )
  );
};

export default BottomNav;
