import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <div className="subpage-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="subpage-header-box">
                <h1 className="text-anime-style-3">Terms and Conditions</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <p></p>
              <p className="mb-4">
                Welcome to <b>Shrewdmind Private Ltd.</b> ("we", "our", "us").
                By accessing or using our website (www.shrewdmind.com) and any
                related services, you ("Customer" or "you") agree to comply with
                and be bound by the following Terms and Conditions. Please read
                these terms carefully before using our services. If you do not
                agree with any of the terms, you should not access or use our
                website or services.
                <ul>
                  <li>
                    Acceptance of Terms By using our website, placing an order,
                    or accessing any services provided by Shrewdmind Private
                    Ltd., you acknowledge that you have read, understood, and
                    agree to these Terms and Conditions. These terms may be
                    updated from time to time, and it is your responsibility to
                    check for updates. Continued use of the website and services
                    after any changes constitutes your acceptance of the new
                    terms.
                  </li>
                  <li>
                    {" "}
                    Account Registration To place orders and access certain
                    features of our website, you may need to create an account.
                    When registering for an account, you agree to provide
                    accurate, current, and complete information and to update
                    your details if they change. You are responsible for
                    maintaining the confidentiality of your account and
                    password, and for all activities under your account.
                    Eligibility: You must be at least 18 years of age or have
                    the consent of a legal guardian to use our website and
                    services. Security: You agree to notify us immediately of
                    any unauthorized use of your account or breach of security.
                  </li>
                  <li>
                    Product Information & Availability We strive to ensure that
                    all product descriptions, images, and pricing are accurate.
                    However, we do not guarantee that the product descriptions,
                    pricing, or availability are free from errors. In the event
                    of an error, we reserve the right to correct the mistake and
                    notify you of the change. If a product is unavailable, we
                    will attempt to offer an alternative or cancel the order.
                    Product Availability: All products are subject to
                    availability. In the event that an item is out of stock, we
                    will inform you as soon as possible. Pricing: Prices are
                    subject to change without notice. Additional taxes, delivery
                    fees, and handling charges may apply depending on your
                    location and the specifics of your order.
                  </li>
                  <li>
                    Ordering Process When placing an order on our website, you
                    agree to the following: Order Confirmation: After you place
                    an order, we will send an email confirming the order
                    details. This is not an acceptance of your order. Your order
                    is only accepted once we process and ship the items.
                    Payment: You agree to provide valid and accurate payment
                    information. We use secure payment gateways to process your
                    payments. Order Cancellation: You may cancel or modify your
                    order only before it has been shipped. If you wish to
                    cancel, please contact our customer support immediately.
                    Once the order has been dispatched, it cannot be canceled.
                  </li>
                  <li>
                    {" "}
                    Shipping and Delivery We offer shipping to various locations
                    as specified on our website. Delivery times are estimates
                    and may vary depending on the location, product
                    availability, and delivery method selected. Shipping
                    Charges: Shipping costs are calculated based on your
                    delivery address and the size of your order. Additional fees
                    may apply for expedited shipping or remote locations.
                    Delivery Time: While we strive to ensure timely delivery, we
                    are not responsible for delays caused by unforeseen
                    circumstances such as weather, strikes, or courier delays.
                    Shipping Risk: Once your order has been shipped, the risk of
                    loss or damage to the products passes to you.
                  </li>
                  <li>
                    {" "}
                    Returns, Exchanges, and Refunds We want you to be satisfied
                    with your purchase. If for any reason you are not satisfied,
                    you can return items within 02 days from the date of
                    delivery, subject to the following conditions: Eligibility:
                    Items must be unused, unopened, and in their original
                    packaging. Certain perishable items, such as food, may not
                    be eligible for returns. Returns Process: To initiate a
                    return, please contact our customer support team for
                    instructions. You will be responsible for return shipping
                    unless the item is defective or incorrect. Refunds: If
                    eligible for a refund, we will process it within 2-5
                    business days after we receive the returned item. Refunds
                    will be issued to the original payment method.
                  </li>
                  <li>
                    Pricing and Payment All prices on our website are listed in
                    INR. Payments can be made using accepted methods such as
                    credit/debit cards, digital wallets, and other payment
                    systems specified on the website. By providing payment
                    information, you authorize us to charge the total cost of
                    your order, including applicable taxes and shipping fees.
                    Payment Security: We use industry-standard encryption to
                    secure your payment information. Your credit card details
                    are not stored on our servers. Currency: Prices are listed
                    in the currency applicable to your location. Currency
                    conversion fees may apply if you are using a non-local
                    payment method.
                  </li>
                  <li>
                    {" "}
                    User Conduct You agree to use our website and services only
                    for lawful purposes and in a manner consistent with the
                    applicable local, state, and international laws. You agree
                    not to: Engage in any fraudulent activities or misrepresent
                    yourself when using the website. Distribute any viruses,
                    malware, or other harmful content. Attempt to interfere with
                    or disrupt the functioning of the website, server, or
                    network. Violate any intellectual property rights or
                    proprietary content.{" "}
                  </li>
                  <li>
                    {" "}
                    Intellectual Property The content, logos, images, product
                    names, and other materials available on our website are the
                    property of Shrewdmind Private Ltd. and are protected by
                    intellectual property laws. You may not use, copy, or
                    distribute any content without our prior written consent,
                    except for personal, non-commercial use.
                  </li>
                  <li>
                    Limitation of Liability To the fullest extent permitted by
                    applicable law, Shrewdmind Private Ltd. shall not be liable
                    for any indirect, incidental, special, consequential, or
                    punitive damages arising out of or related to the use of our
                    website or services. Product Limitations: We make no
                    warranties, express or implied, regarding the products sold
                    on our platform, except for those explicitly stated. The
                    customer assumes all risk associated with the use of the
                    products purchased.
                  </li>
                  <li>
                    {" "}
                    Privacy Your privacy is important to us. Please refer to our
                    Privacy Policy for detailed information on how we collect,
                    use, and protect your personal data.
                  </li>
                  <li>
                    Termination We reserve the right to suspend or terminate
                    your access to the website and services at our sole
                    discretion, without notice, if we believe you have violated
                    any of these Terms and Conditions.{" "}
                  </li>
                  <li>
                    Force Majeure We shall not be liable for any failure or
                    delay in the performance of our obligations under these
                    Terms and Conditions due to causes beyond our reasonable
                    control, including but not limited to acts of nature, war,
                    strikes, or government actions.{" "}
                  </li>
                  <li>
                    {" "}
                    Governing Law and Dispute Resolution These Terms and
                    Conditions shall be governed by and construed in accordance
                    with the laws of Jurisdiction. Any disputes arising from the
                    use of this website or these Terms and Conditions shall be
                    resolved through binding arbitration in Delhi, and you
                    consent to the exclusive jurisdiction of the courts in that
                    location.
                  </li>
                  <li>
                    {" "}
                    Amendments to Terms We reserve the right to modify these
                    Terms and Conditions at any time. Any changes will be posted
                    on this page, and the revised version will be effective as
                    of the date indicated. Your continued use of the website and
                    services after such modifications constitutes your
                    acceptance of the updated terms.
                  </li>
                  <li>
                    Contact Us If you have any questions about these Terms and
                    Conditions or need assistance, please feel free to contact
                    us: Shrewdmind Private Ltd. Thank you for choosing
                    Shrewdmind Private Ltd.! We look forward to providing you
                    with the best online shopping experience.
                  </li>
                </ul>
              </p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <p style={{ marginLeft: "10px" }}>support@pickupskart.com</p>
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i className="fa fa-address-book" aria-hidden="true"></i>
                    <p style={{ marginLeft: "10px" }}>
                      +91- 8076877163 , +91- 8059942293
                    </p>
                  </h5>
                </div>
                <div className="col-sm-9 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i className="fa fa-address-book" aria-hidden="true"></i>
                    <p style={{ marginLeft: "10px" }}>
                      D-298 Street No 11 Harsh Vihar, 110093 New Delhi
                    </p>
                  </h5>
                </div>
              </div>           
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
