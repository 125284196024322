import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import "./Header.css";
import LoginModal from "../pages/LoginModal";
import { getCart, venderDetail } from "../Api/AllApi";
import { baseUrl } from "../env";
import { insertGlobalData } from "../Api/saveData";
import { BsCart3 } from "react-icons/bs";
import { CiWallet } from "react-icons/ci";
import { IconContext } from "react-icons";
import { FaSearch } from "react-icons/fa";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const [count, setCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [show, setShow] = useState(false);
  const [vendorData, setVendorData] = useState("");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [Token, setToken] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      let vendorId = localStorage.getItem("vendorId");
      if (vendorId) {
        venderDetail(vendorId)
          .then((res) => {
            if (res.data.data) {
              setVendorData({ ...res.data.data });
              setIsOpen(res.data.data.isShop);
              insertGlobalData("vendorInfo", res.data.data);
              createVendorEvent()
              let vendorList = localStorage.getItem("vendorList")
                ? JSON.parse(localStorage.getItem("vendorList"))
                : [];
              let vendorObj = vendorList.find((item) => item._id === vendorId);
              if (!vendorObj) {
                vendorList.unshift({ ...res.data.data, _id: vendorId });
                if (vendorList.length > 5) vendorList.pop();
                localStorage.setItem("vendorList", JSON.stringify(vendorList));
              }
            }
          })
          .catch((err) => {
            setIsOpen(false);
            console.log(err, "error");
          });
        getCartDetails();
      }
    }, 100);
  }, [location]);

  // useEffect()
  const handleShow = () => {
    setShow(!show);
  };

  const createVendorEvent = () => {
    const event = new Event("vendorInfo");
    document.dispatchEvent(event);
  };

  const getCartDetails = () => {
    let token = localStorage.getItem("token");
    let vendorId = localStorage.getItem("vendorId");
    if (token && vendorId) {
      getCart(vendorId).then((resp) => {
        const totalObj = { total: 0, ftotal: 0, totalQty: 0 };
        let cartList = resp.data.data;
        cartList.forEach((item) => {
          totalObj.totalQty += item.qty;
          item.fprice = item.productId.price;
          item.productId.offerPrice?.forEach((price) => {
            if (price.qty <= item.qty) {
              item.fprice =
                item.productId.price -
                (item.productId.price * price.price) / 100;
            }
          });
          totalObj.total += item.qty * item.productId.price;
          totalObj.ftotal += item.qty * item.fprice;
        });
        setTotalAmount(totalObj.ftotal);
        setCount(totalObj.totalQty);
        insertGlobalData("cart", { cartList, totalObj });
      });
    }
  };

  const getEvent = (a) => {
    document.addEventListener("cart", (e) => {
      console.log("-----------------------", e);
      if (e.isAddtoCart) {
        getCartDetails();
      } else {
        setCount(e.qty);
        setTotalAmount(e.amount);
      }
      // getItemCount();
    });

    document.addEventListener("login", () => {
      let t = localStorage.getItem("token");
      setToken(t);
    });
  };
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    getEvent();
  }, []);

  const logOut = () => {
    localStorage.removeItem("token");
    setToken("");
    navigate("/");
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const loginCheck = () => {
    if (!localStorage.getItem("token")) {
      setShow(true);
    } else {
      navigate("/cart");
    }
  };

  // Function to toggle the search tab
  const toggleSearch = () => {
    console.log("test", isSearch);
    setIsSearch(!isSearch);
  };

  return (
    <div className="header-fix">
      <div
        className={`humberger__menu__overlay ${
          mobileMenuOpen === true ? "active" : ""
        }`}
      ></div>
      <div
        className={`humberger__menu__wrapper ${
          mobileMenuOpen === true ? "show__humberger__menu__wrapper" : ""
        }`}
      >
        <div className="humberger__menu__logo header_flex">
          <img src="../Assets/images/logo.png" alt="" />
          <span onClick={toggleMobileMenu}>X</span>
        </div>

        <div className="humberger__menu__widget"></div>

        <div id="mobile-menu-wrap"></div>
        <div className="header__top__right__social">
          <ul style={{ listStyle: "none" }}>
            <li className="active">
              <Link to="/shopList">Store near me</Link>
            </li>
            {Token && (
              <li>
                <Link to="/orderlist" onClick={toggleMobileMenu}>
                  Orders
                </Link>
              </li>
            )}

            <li>
              <Link to="/contactus" onClick={toggleMobileMenu}>
                Contact
              </Link>
            </li>
            <li>
              {Token ? (
                <span onClick={logOut} className="loginbtn">
                  Logout
                </span>
              ) : (
                <span onClick={handleShow} className="loginbtn">
                  Login
                </span>
              )}
            </li>
          </ul>
        </div>
        <div className="header__top__right__auth"></div>
        <div className="humberger__menu__contact">
          <ul>
            <li>support@pickupskart.com</li>
            {/* <li>Free Shipping for all Order of ₹1000</li> */}
          </ul>
        </div>
      </div>

      <header className="headerStyle">
        <div className="header__top">
          <div className="container-lg">
            <div className="row">
              <div className="col-lg-6">
                <div className="header__top__left">
                  <ul>
                    <li>
                      <i className="fa fa-envelope"></i>support@pickupskart.com
                    </li>
                    <Link to="/shopList">
                      <i className="fa fa-street-view"></i>Store near me
                    </Link>

                    {/* <li>Free Shipping for all Order of ₹1000</li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="header__top__right">
                  <div className="header__top__right__social">
                    <button className={`toggle-btn ${isOpen ? "on" : "off"}`}>
                      Shop is {isOpen ? "Open" : "Closed"}
                    </button>
                  </div>
                  <div className="header__top__right__auth">
                    <i className="fa fa-user"> </i>{" "}
                    {!Token ? (
                      <span onClick={handleShow} className="loginbtn">
                        Login
                      </span>
                    ) : (
                      <span onClick={logOut} className="loginbtn">
                        Logout
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container vendor_detail ">
          <div className="row">
            <div className="col-lg-3 logo_cover vendor_detail_logo">
              <Link to="/">
                {vendorData.logo ? (
                  <img
                    src={baseUrl + "/file/load/" + vendorData?.logo}
                    alt="na"
                    className="vendor__logo"
                  />
                ) : (
                  <span className="vendor__logo_blank"></span>
                )}
                <span className="vendor_name"> {vendorData?.organization}</span>
              </Link>
            </div>
            <div className="col-lg-6">
              <nav className="header__menu">
                <ul>
                  {/* <li>
                    <Link to="/">Home</Link>
                  </li> */}
                  {Token && (
                    <li>
                      <Link to="/orderlist">Orders</Link>
                    </li>
                  )}

                  {/* <li>
                    <Link to="/contactus">Contact</Link>
                  </li> */}
                </ul>
              </nav>
            </div>
            <div className="col-lg-3">
              <div className="header__cart">
                <ul>
                  <li>
                    <div className="search-container">
                      {/* Search Icon */}
                      <button
                        onClick={toggleSearch}
                        className="search-icon-btn"
                      >
                        <IconContext.Provider
                          value={{
                            color: "white",
                            size: "18px",
                          }}
                        >
                          <div>
                            <FaSearch />
                          </div>
                        </IconContext.Provider>
                      </button>

                      {/* Collapsible Search Tab */}
                      {isSearch && (
                        <div className="search-tab">
                          <input type="text" placeholder="Search..." />
                        </div>
                      )}
                    </div>  
                  </li>
                  {Token && (
                    <li>
                      <IconContext.Provider
                        value={{
                          color: "white",
                          size: "22px",
                          style: { strokeWidth: "1" },
                        }}
                      >
                        <Link to="/wallet">
                          <CiWallet />
                        </Link>
                      </IconContext.Provider>
                    </li>
                  )}
                  {Token && (
                    <li>
                      <div className="cart-item-count" onClick={loginCheck}>
                        <IconContext.Provider
                          value={{ color: "white", size: "20px" }}
                        >
                          <div>
                            <BsCart3 />
                          </div>
                        </IconContext.Provider>

                        {count ? <span>{count}</span> : ""}
                      </div>
                    </li>
                  )}
                </ul>
                {/* <div className="header__cart__price">
                  <span className="price_property">₹{totalAmount}</span>
                </div> */}
              </div>
            </div>
          </div>
          <div className="humberger__open" onClick={toggleMobileMenu}>
            <i className="fa fa-bars"></i>
          </div>
        </div>
      </header>
      <LoginModal setShow={setShow} show={show} />
    </div>
  );
}

export default Header;
