// OrderDetails.js
import React, { useEffect, useState } from "react";
import "./BottomNav.css";
import "./OrderDetails.css";
import { FaDownload } from 'react-icons/fa';
import { useParams } from "react-router-dom";
import { getOrderdetailsByOrderId } from "../Api/AllApi";
import { baseUrl } from "../env";

const OrderDetails = () => {
  let { orderId } = useParams();
  let [ orders,setOrders ] = useState({});
  useEffect(()=>{
     getOrderdetailsByOrderId(orderId)
     .then(resp=>{
        console.log('==========================',resp.data.data)
        setOrders(resp.data.data)
     }).catch(err=>{
      console.log(err)
     })
  },[])

  const orderDetails = [
    {
      id: 1,
      name: "Product 1",
      price: 50,
      quantity: 2,
      image: "https://via.placeholder.com/100",
    },
    {
      id: 2,
      name: "Product 2",
      price: 30,
      quantity: 1,
      image: "https://via.placeholder.com/100",
    },
    {
      id: 3,
      name: "Product 3",
      price: 20,
      quantity: 3,
      image: "https://via.placeholder.com/100",
    },
    {
      id: 4,
      name: "Product 3",
      price: 20,
      quantity: 3,
      image: "https://via.placeholder.com/100",
    },
    {
      id: 5,
      name: "Product 3",
      price: 20,
      quantity: 3,
      image: "https://via.placeholder.com/100",
    },
    {
      id: 6,
      name: "Product 3",
      price: 20,
      quantity: 3,
      image: "https://via.placeholder.com/100",
    },
    {
      id: 7,
      name: "Product 3",
      price: 20,
      quantity: 3,
      image: "https://via.placeholder.com/100",
    },
  ];
  const calculateTotalPrice = (order) => order.price * order.quantity;

  return (
    <div className="container order-details">
      <ul>
        {orders?.products && orders?.products?.map((product) => (
          <li key={product.id} className="product-item">
            <div className="product-image">
              <img loading="lazy" src={baseUrl + "file/load/" + product?.images[0]} alt={product.name} />
            </div>
            <div className="product-info">
              <div className="product-name">{product.name}</div>
              <div className="product-price">Price: ₹{product.fprice}</div>
              <div className="product-quantity">
                Qty: {product.qty}
              </div>
              <div className="product-total">
                Total: ₹{product.fprice*product.qty}
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="order-total">
        Selling Price: 
        ₹{orders.offerAmount}
      </div>
      <div className="order-total">
        Delivery Charge: 
        ₹{orders.deliveryCharges}
      </div>
      <div className="order-total">
        <strong>Total : 
        ₹{orders.offerAmount+orders.deliveryCharges} </strong>
      </div>
     <button className="download-button"> <FaDownload /> Invoice</button>
    </div>
  );
};

export default OrderDetails;
