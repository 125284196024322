import axios from "axios";
import "./App.css";
import AllRoute from "./Route/AllRoute";
import { useState } from "react";
import BottomNav from "./pages/BottomNav";


function App() {
  const [loader, setLoader] = useState(false);

  axios.interceptors.request.use(
    (request) => {
      setLoader(true);
      if (request) {
        request.headers.token = localStorage.getItem("token");
      }
      return request;
    },
    (error) => {
      setLoader(false);
      return Promise.reject(error.message);
    }
  );

  axios.interceptors.response.use((resp) => {
    setLoader(false);
    return resp;
  }, (error) => {
    setLoader(false);
    return Promise.reject(error);
  });

  return (
    <div className="d-flex flex-column min-vh-100">
      {loader && (
        <div className="loader-container">
          <div className="spinner-grow" style={{ width: '3rem', height: '3rem' }} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <AllRoute />
      <BottomNav />
    </div>
  );
}

export default App;
